import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import i18n from '@/common/locale/i18n'
import { jsonApi } from '@/services/jsonApi'

import type { AppDispatch, AppState, RootState } from '../app/store'
import type { CurrencyType } from '../user/types'
import type {
  CategoryLabelListItem,
  GameFile,
  GameListItem,
  ManulacturerListItem,
} from './gameInterface'
import getGameData from './performance-utils/getGameData'
import getSortedCate from './performance-utils/getSortedCate'
import getSortedList from './performance-utils/getSortedList'
import getSortedManu from './performance-utils/getSortedManu'
import { MINIMAL_BET } from './third-game/ThirdGameMinimalBet'

export type FreeTryModalStatus = 'normal' | 'notLogin' | 'notEnoughBalance'

interface FreeTryState {
  isFreeTryMode: boolean
}

interface GameSlice {
  showTips: boolean
  tipsText: string
  // 启用 && 在首页展示
  manus: ManulacturerListItem[]
  // 启用& & 不再维护中
  enableManus: ManulacturerListItem[]
  allManus: ManulacturerListItem[]
  games: GameListItem[]
  GMPGames: GameListItem[]
  selfGames: GameListItem[]
  gmpInhouseGame: GameListItem[]
  catesKeyByLabelId: Record<string | number, CategoryLabelListItem>
  catesKeybyLabelType: Record<string | number, CategoryLabelListItem[]>
  manusKeybyId: Record<string | number, ManulacturerListItem>
  enableManusKeybyId: Record<string | number, ManulacturerListItem>
  freeTry: FreeTryState
  gameDataLoaded: boolean
  gmpJili: GameListItem[] 
  gmpFundist: GameListItem[] 
  gmpClassic: GameListItem[] 
  gmpFacai: GameListItem[]
  gmpPgsoft: GameListItem[]
}

export const FAVORITE_LABEL_ID = 999

/**
 * 整理游戏文件数据
 * @param data
 * @returns
 */
function formatGameData(data: GameFile) {
  const { game_list, manulacturer_list, category_label_list } = data
  /** 优化 */
  const collectionCate: CategoryLabelListItem = {
    label_type: 2,
    label_id: FAVORITE_LABEL_ID,
    label_name: i18n.t('FAVORITE_GAME'),
    icon: '/assets/images/favorite-icon.png',
    is_enabled: 1,
    display_games: 1,
    sort_weight:
      (category_label_list.find(item => item.label_id === 6)?.sort_weight ??
        0) + 1,
    game_sort: [],
    data: [],
    game_show_detail: '{}',
    providerSort: [],
  }

  const {
    manusKeybyId,
    list: manus,
    enableManus,
    enableManusKeybyId,
  } = getSortedManu(manulacturer_list)
  const cates = isFeatureEnabled('gameCollection')
    ? [collectionCate, ...category_label_list]
    : category_label_list
  const { catesKeyByLabelId, catesKeybyLabelType } = getSortedCate(
    cates,
    game_list,
    enableManusKeybyId,
  )
  const {
    games,
    GMPGames,
    selfGames,
    gmpInhouseGame,
    gmpClassic,
    gmpFacai,
    gmpPgsoft,
    gmpFundist,
    gmpJili,
  } = getSortedList(game_list)
  
  getGameData({
    manusKeybyId,
    catesKeyByLabelId,
    catesKeybyLabelType,
    games,
    enableManusKeybyId,
  })

  const endManus = manus.filter(manu => manu.data.length > 0)
  const endEnableManus = enableManus.filter(manu => manu.data.length > 0)
  /** 优化 */

  return {
    allManus: manulacturer_list,
    manus: endManus,
    enableManus: endEnableManus,
    games: games,
    GMPGames: GMPGames,
    selfGames: selfGames,
    gmpInhouseGame: gmpInhouseGame,
    manusKeybyId,
    enableManusKeybyId,
    catesKeyByLabelId,
    catesKeybyLabelType,
    gmpClassic:gmpClassic,
    gmpFacai:gmpFacai,
    gmpPgsoft:gmpPgsoft,
    gmpFundist:gmpFundist,
    gmpJili:gmpJili,
  }
}

const initialState: GameSlice = {
  games: [],
  GMPGames: [], // GMP游戏中台游戏列表
  selfGames: [], // 平台的自营游戏
  gmpInhouseGame: [], // 接入GMP的自营游戏
  gmpFacai: [],
  gmpPgsoft:[],
  gmpClassic: [],
  gmpFundist: [],
  gmpJili: [],
  manus: [],
  enableManus: [],
  allManus: [],
  catesKeyByLabelId: {},
  catesKeybyLabelType: {},
  manusKeybyId: {},
  showTips: false,
  tipsText: '',
  freeTry: {
    isFreeTryMode: false,
  },
  enableManusKeybyId: {},
  gameDataLoaded: false,
}

export const gameSlice = createSlice({
  name: 'gameList',
  initialState,
  reducers: {
    setIsFreeTryMode: (state, action: PayloadAction<boolean>) => {
      state.freeTry.isFreeTryMode = action.payload
    },

    switchShowTips: (state, action) => {
      if (!state.showTips) {
        state.tipsText = action.payload || ''
      }
      state.showTips = !state.showTips
    },
    showTips: (state, action) => {
      state.tipsText = action.payload || ''
      state.showTips = true
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      jsonApi.endpoints.getGameList.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        const { payload } = action
        const {
          games,
          GMPGames,
          selfGames,
          gmpInhouseGame,
          catesKeyByLabelId,
          catesKeybyLabelType,
          manusKeybyId,
          manus,
          enableManus,
          enableManusKeybyId,
          allManus,
          gmpClassic,
          gmpFacai,
          gmpPgsoft,
          gmpFundist,
          gmpJili
        } = formatGameData(payload)

        state.manus = manus
        state.games = games
        state.catesKeyByLabelId = catesKeyByLabelId
        state.catesKeybyLabelType = catesKeybyLabelType
        state.manusKeybyId = manusKeybyId
        state.GMPGames = GMPGames
        state.selfGames = selfGames
        state.gmpInhouseGame = gmpInhouseGame
        state.allManus = allManus
        state.enableManus = enableManus
        state.enableManusKeybyId = enableManusKeybyId
        state.gameDataLoaded = true

        state.gmpClassic = gmpClassic
        state.gmpFacai = gmpFacai
        state.gmpPgsoft = gmpPgsoft
        state.gmpFundist = gmpFundist
        state.gmpJili = gmpJili

      },
    )
  },
})

export const { switchShowTips, showTips, setIsFreeTryMode } = gameSlice.actions

export const gameReducer = gameSlice.reducer
export const selectGame = (state: RootState) => state.gameModal
export const getShowTips = (state: RootState) => selectGame(state).showTips

export const getTipsText = (state: RootState) => selectGame(state).tipsText

export const selectGameList = (state: RootState) => selectGame(state).games

export const selectAllGame = createSelector(
  selectGame,
  gameState => gameState.games,
)

// 所有GMP游戏（游戏中台的游戏）
export const selectAllGMPGames = createSelector(
  selectGame,
  gameState => gameState.GMPGames,
)

// 所有平台自营游戏
export const selectAllSelfGames = createSelector(
  selectGame,
  gameState => gameState.selfGames,
)

// 自营游戏接入GMP的游戏
export const selectGmpInhouseGame = createSelector(
  selectGame,
  gameState => gameState.gmpInhouseGame,
)

export const selectAllGameIncludeSelf = createSelector(
  selectAllGame,
  selectGmpInhouseGame,
  selectAllSelfGames,
  (games, selfGames) => selfGames.concat(games),
)
// 所有的标签、分类列表
export const selectAllCateByLableType = createSelector(
  selectGame,
  game => game.catesKeybyLabelType,
)

export const selectAllCateByLableId = createSelector(
  selectGame,
  gameState => gameState.catesKeyByLabelId,
)

// 所有在游戏列表展示的厂商哈希表
export const selectManuObj = createSelector(
  selectGame,
  gamesState => gamesState.manusKeybyId,
)

// 所有的厂商列表
export const selectEnableManuObj = createSelector(
  selectGame,
  gamesState => gamesState.enableManusKeybyId,
)

// 启用 && 在游戏列表展示的厂商列表
export const selectManuList = createSelector(
  selectGame,
  gamesState => gamesState.manus,
)

export const selectManufTabItems = createSelector(selectManuList, manuList =>
  manuList.map(({ id, name, icon }) => ({
    id,
    name,
    icon,
  })),
)

// 启用 && 不在维护中的厂商列表
export const selectEnableManuList = createSelector(
  selectGame,
  gamesState => gamesState.enableManus,
)

// 所有的厂商列表
export const selectAllManuList = createSelector(
  selectGame,
  gamesState => gamesState.allManus,
)

export const selectIsFreeMode = createSelector(
  selectGame,
  gamesState => gamesState.freeTry.isFreeTryMode,
)

export const selectFreeTryModalStatus = createSelector(
  ({ user: { user } }: RootState) =>
    user?.balance_map[getCurrencyCode() as CurrencyType]?.amount || 0,
  ({ act: { bankruptTrigger } }: RootState) =>
    bankruptTrigger?.gmp ?? MINIMAL_BET,
  ({ user: { userId, token } }: RootState) => userId && token,
  (userBalance, gmpBankruptThreshold, isLogin) => {
    const isBankruptInGmp = userBalance < gmpBankruptThreshold
    if (!isLogin) return 'notLogin'
    if (isBankruptInGmp) return 'notEnoughBalance'
    return 'normal'
  },
)

export const initFreeTryMode = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('game/initFreeTryModeStart', (_, { dispatch, getState }) => {
  dispatch(setIsFreeTryMode(selectFreeTryModalStatus(getState()) !== 'normal'))
})

export const selectGameDataLoaded = createSelector(
  selectGame,
  gamesState => gamesState.gameDataLoaded,
)

export const selectAllGMPFundistGames = createSelector(
  selectGame,
  gameState => gameState.gmpFundist,
)

export const selectAllGMPFacaiGames = createSelector(
  selectGame,
  gameState => gameState.gmpFacai,
)

export const selectAllGMPJiliGames = createSelector(
  selectGame,
  gameState => gameState.gmpJili,
)

export const selectAllGMPClassicGames = createSelector(
  selectGame,
  gameState => gameState.gmpClassic,
)

export const selectAllGMPPgSoftGames = createSelector(
  selectGame,
  gameState => gameState.gmpPgsoft,
)

